import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';
import { isPoetProject } from 'utils';

const isPoet = isPoetProject();

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    // Use this if we want to dispatch the drawer state with the breakpoint
    // const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    // const dispatch = useDispatch();
    // React.useEffect(() => {
    //     dispatch(openDrawer(!matchDownMd));
    // }, [dispatch, matchDownMd]);
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    const header = useMemo(
        () => (
            <Toolbar sx={{ paddingBottom: 0 }}>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            {/* beamer embed */}
            <Helmet>
                <script>
                    {`var beamer_config = {
                            product_id: '${process.env.REACT_APP_BEAMER_PRODUCT_ID}'
                        };`}
                </script>
                <script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer />
            </Helmet>

            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: isPoet ? theme.palette.secondary.main : theme.palette.background.default,
                    boxShadow: isPoet ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none',
                    paddingBottom: '0 !important'
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={drawerOpen} style={{ padding: '0', maxHeight: 'calc(100vh - 88px)' }}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;
