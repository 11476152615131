export const randomInteger = () => Math.floor(Math.random() * 100000);

/**
 *  Returns only rows that were update base on its id
 *
 * @param currentRows {Array} current state of the rows
 * @param updatedRows {Array} new state of the rows
 */
export const getOnlyUpdatedRows = <T extends Record<string, any>>(currentRows: T[], updatedRows: T[]) => {
    const filteredRows: Record<string, any>[] = [];

    const indexedCurrentRows = currentRows.reduce<Record<string, any>>((acc, el) => ({ ...acc, [el.id as number]: el }), {});
    for (const row of updatedRows) {
        if (JSON.stringify(row) !== JSON.stringify(indexedCurrentRows[row.id as number])) filteredRows.push(row);
    }

    return filteredRows;
};

/**
 * Returns the list with the new order
 *
 * @param list {Array}
 * @param startIndex {Number} Index of the modified row
 * @param endIndex {Number} New index of the modified row
 */
export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Returns the same row alphabetically sorted by the given field
 *
 * @param rows {Array} rows to be ordered
 * @param fieldToUse {String} key of the row to use to order
 */
export const orderAlphabetical = <T extends Record<string, any>>(rows: T[], fieldToUse: string) =>
    [...rows]
        .sort((a, b) => a[fieldToUse].toLowerCase().localeCompare(b[fieldToUse].toLowerCase()))
        .map((row, idx) => ({ ...row, order: idx + 1 }));

/**
 * Dispatches an event to the global window object with the name 'grid-edit-pre-process'.
 * This event is used to allow external components to listen and react to the edit cell pre-process event of the grid.
 *
 * @param {Object} params - The params object to be passed as the event detail
 */
export const dispatchCellEditPreprocessor = (params: any) => {
    window.dispatchEvent(
        new CustomEvent('grid-edit-pre-process', {
            detail: { ...params }
        })
    );
};
