import { useQuery } from '@apollo/client';
import { QUERY_GET_MY_ITEM_ACTIONS } from 'graphql/queries/layout';
import { QUERY_GET_MENU_ITEMS } from 'graphql/queries/menuItems';
import { IAppModule } from 'layout/MainLayout/Sidebar/MenuList/types';
import { getAllMenuItems, getFilteredMenu, getMyMenuItems } from 'layout/MainLayout/Sidebar/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'store';
import { saveActionData, saveMenuItemList } from 'store/slices/menu';
import { IGetItemActions, IMenuItemActionRoleActions } from 'types/layout';

const useMyMenuItems = () => {
    const dispatch = useDispatch();
    const { loading: menuLoading, data: menuData } = useQuery<{ SaasMenu: IAppModule[] }>(QUERY_GET_MENU_ITEMS);
    const { loading: actionsLoading, data: actionsData } = useQuery<IGetItemActions>(QUERY_GET_MY_ITEM_ACTIONS);
    const myMenuItemsIds = React.useMemo(
        () => getMyMenuItems(actionsData?.SaasMyRoleMenuItemActions ?? []),
        [actionsData?.SaasMyRoleMenuItemActions]
    );

    const filteredList: IAppModule[] = useMemo(
        () => getFilteredMenu(menuData?.SaasMenu ?? [], myMenuItemsIds),
        [menuData?.SaasMenu, myMenuItemsIds]
    );
    const menuItemList = useMemo(() => getAllMenuItems(filteredList) || [], [filteredList]);

    const getMenuItemByRecordTypeId = (recordTypeId: number) => {
        const selectedMenuItem = menuItemList.find((el) => Number(el.recordType?.id) === recordTypeId);
        return selectedMenuItem?.fullPath;
    };

    const getMenuPermissionsByRecordTypeId = useCallback(
        (recordTypeId: number, roleId?: number) => {
            const filteredRoleMenuItemActions = actionsData?.SaasMyRoleMenuItemActions.filter(
                (el) => Number(el.menuItem?.recordType?.id) === recordTypeId && Number(el.role?.id) === Number(roleId)
            );

            const permissionsActions: IMenuItemActionRoleActions[] = [];

            filteredRoleMenuItemActions?.forEach((el) => {
                el.menuItemActions?.forEach((action) => {
                    permissionsActions.push(action);
                });
            });
            return permissionsActions;
        },
        [actionsData?.SaasMyRoleMenuItemActions]
    );

    useEffect(() => {
        if (menuItemList) dispatch(saveMenuItemList(menuItemList));
    }, [menuItemList, dispatch]);

    useEffect(() => {
        if (actionsData) dispatch(saveActionData(actionsData.SaasMyRoleMenuItemActions || []));
    }, [actionsData, dispatch]);

    if (menuLoading || actionsLoading) return { loading: true };
    return { loading: false, filteredList, menuItemList, getMenuItemByRecordTypeId, getMenuPermissionsByRecordTypeId };
};

export default useMyMenuItems;
