/* eslint-disable no-nested-ternary */
import React from 'react';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@mui/styles';
import { Bolt, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Clear, Event } from '@mui/icons-material';
import {
    Checkbox,
    Chip,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { IRecordField } from 'ui-component/records/types';
import { PanelFieldSection } from 'views/CreateRecord/components';
import { getDateByHour } from 'ui-component/records/utils';
import { IUser } from 'views/backoffice/users/types';
import { isJsonString } from 'utils/stringHelpers';
import { DROPDOWN_COLUMNS } from 'ui-component/records/utils/columnHelpers';
import { generateInlineObject } from '../PropertiesPanel/utils';

export interface renderInputProps {
    fieldData: IRecordField;
    resetField: any;
    name: string;
    control: any;
    errors: any;
    options?: any[];
    isCustomList?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
    setValue?: any;
    fieldName?: string;
}

const useStyles = makeStyles((theme) => ({
    textareaVResize: {
        '& textarea': { resize: 'vertical' }
    }
}));

export const RenderInputBreakLine = ({
    fieldData,
    name,
    control,
    errors,
    options = [],
    isCustomList,
    disabled,
    isRequired,
    resetField,
    setValue,
    fieldName
}: renderInputProps) => {
    const themeSys = useTheme();
    const classes = useStyles();

    const isRequiredName = isRequired ? (
        <>
            <FormattedMessage id={name} defaultMessage={name} />
            <span style={{ color: 'red' }}>{' *'}</span>
        </>
    ) : (
        <FormattedMessage id={name} defaultMessage={name} />
    );

    if (fieldData.dataType.toLowerCase() === 'dropdown' || DROPDOWN_COLUMNS.includes(fieldName as (typeof DROPDOWN_COLUMNS)[number])) {
        const isListOfObjects = fieldData.listType?.dataType === 'Object';

        const formattedOptions = isListOfObjects
            ? options.map((el) => ({ ...el, label: generateInlineObject(el.dynamicObjectValue.objectValues) }))
            : options;

        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                        <TextField
                            variant="outlined"
                            select
                            error={!!errors[name]}
                            helperText={errors[name]?.message}
                            fullWidth
                            {...field}
                            disabled={disabled}
                            sx={{
                                '& fieldset': {
                                    border: 'none !important'
                                }
                            }}
                            {...(field.value &&
                                field.value !== '0' &&
                                !disabled && {
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="remove selected option"
                                                    data-test-id="remove-selected-option"
                                                    data-test-input={`test-id-${field.name}`}
                                                    onClick={() => {
                                                        resetField(field.name, { defaultValue: '0' });
                                                    }}
                                                    edge="end"
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    },
                                    SelectProps: { IconComponent: () => null }
                                })}
                        >
                            {formattedOptions.map(
                                (option: { id: string; name?: string; userValue?: IUser; label?: string; value?: string }) => (
                                    <MenuItem key={option.id} value={isCustomList ? option.id : Number(option.id)}>
                                        <em>{isCustomList ? option.userValue?.name || option.label || option.value : option.name}</em>
                                    </MenuItem>
                                )
                            )}
                        </TextField>
                    )}
                />
            </PanelFieldSection>
        );
    }

    if (fieldData.dataType === 'string' || fieldData.dataType === 'url' || fieldData.dataType === 'textarea')
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            multiline={fieldData.dataType === 'textarea'}
                            className={fieldData.dataType === 'textarea' ? classes.textareaVResize : undefined}
                            variant="outlined"
                            error={!!errors[name]}
                            helperText={errors[name]?.message}
                            fullWidth
                            {...field}
                            disabled={disabled}
                            sx={{
                                '& fieldset': {
                                    border: 'none !important'
                                }
                            }}
                        />
                    )}
                />
            </PanelFieldSection>
        );

    if (fieldData.dataType === 'currency' || fieldData.dataType === 'number')
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <NumberFormat
                            customInput={TextField}
                            fixedDecimalScale={fieldData.dataType === 'currency'}
                            thousandSeparator={fieldData.dataType === 'currency' ? ',' : ''}
                            decimalScale={fieldData.dataType === 'currency' ? 2 : 0}
                            variant="outlined"
                            error={!!errors[name]}
                            helperText={errors[name]?.message}
                            fullWidth
                            {...field}
                            disabled={disabled}
                            sx={{
                                '& fieldset': {
                                    border: 'none !important'
                                }
                            }}
                        />
                    )}
                />
            </PanelFieldSection>
        );

    if (fieldData.dataType.toLowerCase() === 'multiselect') {
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                        <>
                            <Autocomplete
                                multiple
                                disabled={disabled}
                                id="tags-outlined"
                                sx={{
                                    '& fieldset': {
                                        border: 'none !important'
                                    }
                                }}
                                renderTags={(value, getTagProps, ownerState) =>
                                    value.map((option: any, index: number) => {
                                        const backgroundColors = [themeSys.palette.secondary.main, '#1845A7', '#005208'];

                                        return (
                                            <Chip
                                                sx={(theme) => ({
                                                    backgroundColor: `${backgroundColors[index % 3]} !important`,
                                                    color: `#fff !important`,
                                                    borderRadius: '20px !important',
                                                    width: '100px'
                                                })}
                                                label={isCustomList ? option.userValue?.name || option.value : option.name}
                                                deleteIcon={<Clear />}
                                                {...getTagProps({ index })}
                                            />
                                        );
                                    })
                                }
                                defaultValue={options.filter((option: any) =>
                                    field.value !== '0' && isJsonString(field.value) && Array.isArray(JSON.parse(field.value))
                                        ? JSON.parse(field.value || '[]').includes(+option.id)
                                        : false
                                )}
                                onChange={(_e, value) => {
                                    setValue(name, value.length === 0 ? '0' : JSON.stringify(value.map((val: any) => +val.id)));
                                }}
                                options={options}
                                getOptionLabel={(option: any) => (isCustomList ? option.userValue?.name || option.value : option.name)}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {errors[name] && (
                                <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                    {errors[name]?.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </PanelFieldSection>
        );
    }

    if (fieldData.dataType === 'date')
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value: datePicker, ref } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ todayButtonLabel: 'NOW' }}>
                            <DatePicker
                                onChange={onChange}
                                inputRef={ref}
                                value={datePicker}
                                disabled={disabled}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            '& fieldset': {
                                                border: 'none !important'
                                            }
                                        }}
                                        fullWidth
                                        {...params}
                                        error={!!errors[name]}
                                        helperText={errors.dueDate?.message}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
            </PanelFieldSection>
        );

    if (fieldData.dataType === 'datetime') {
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value: datePicker, ref } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                onChange={onChange}
                                value={datePicker}
                                inputRef={ref}
                                disabled={disabled}
                                components={{
                                    OpenPickerIcon: () => (
                                        <Tooltip title="Pick a date">
                                            <Event />
                                        </Tooltip>
                                    )
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['today']
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            '& fieldset': {
                                                border: 'none !important'
                                            }
                                        }}
                                        fullWidth
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    <Tooltip title="Now">
                                                        <IconButton
                                                            onClick={() => onChange(new Date())}
                                                            sx={{ transform: 'translateX(15px)', marginLeft: '-20px' }}
                                                        >
                                                            <Bolt />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {params.InputProps?.endAdornment}
                                                </>
                                            )
                                        }}
                                        error={!!errors[name]}
                                        helperText={errors.dueDate?.message}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
            </PanelFieldSection>
        );
    }

    if (fieldData.dataType === 'time') {
        return (
            <PanelFieldSection title={isRequiredName}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value: datePicker, ref } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                onChange={onChange}
                                value={!Date.parse(datePicker) ? getDateByHour(datePicker) : datePicker}
                                inputRef={ref}
                                disabled={disabled}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            '& fieldset': {
                                                border: 'none !important'
                                            }
                                        }}
                                        fullWidth
                                        {...params}
                                        error={!!errors[name]}
                                        helperText={errors.dueDate?.message}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
            </PanelFieldSection>
        );
    }

    if (fieldData.dataType === 'checkbox') {
        return (
            <Grid item xs={12} sx={{ py: '10px' }}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <>
                            <FormGroup style={{ padding: 10 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 600, fontSize: '13px' }}>{name}</Typography>
                                    </Grid>
                                    <Grid xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid xs={6} sx={{ textAlign: 'center' }}>
                                            <Checkbox
                                                defaultChecked
                                                checked={!!field.value && field.value === 'true'}
                                                disabled={disabled}
                                                onChange={(event) => {
                                                    setValue(name, String(event.target.checked));
                                                }}
                                                sx={(theme) => ({
                                                    padding: 0,
                                                    color: theme.palette.primary[400],
                                                    '&.Mui-checked': { color: theme.palette.primary.dark },
                                                    '& .MuiSvgIcon-root': { fontSize: 30 }
                                                })}
                                                icon={<CheckBoxOutlineBlankOutlined />}
                                                checkedIcon={<CheckBoxOutlined />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            {errors[name] && (
                                <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                    {errors[name]?.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
        );
    }

    if (fieldData.dataType === 'multiselect-checkbox') {
        return (
            <Grid item xs={12} sx={{ py: '10px' }}>
                <Typography style={{ paddingTop: 18, paddingLeft: 10, fontWeight: 600, fontSize: '13px' }}>{isRequiredName}</Typography>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <FormGroup style={{ padding: 10 }}>
                            {options.map((option) => (
                                <>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                                    {option.userValue?.name || option.value}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Grid xs={6} sx={{ textAlign: 'center' }}>
                                                    <Checkbox
                                                        defaultChecked
                                                        checked={
                                                            !!field.value &&
                                                            isJsonString(field.value) &&
                                                            Array.isArray(JSON.parse(field.value)) &&
                                                            JSON.parse(field.value).some(
                                                                (x: string | number) => String(x) === String(option.id)
                                                            )
                                                        }
                                                        disabled={disabled}
                                                        onChange={(event) => {
                                                            let currentValue = isJsonString(field.value)
                                                                ? JSON.parse(field.value || '[]')
                                                                : [];
                                                            if (!Array.isArray(currentValue)) {
                                                                currentValue = [];
                                                            }
                                                            if (event.target.checked) {
                                                                currentValue.push(+option.id);
                                                            } else {
                                                                currentValue.splice(currentValue.indexOf(+option.id), 1);
                                                            }
                                                            setValue(name, currentValue.length ? JSON.stringify(currentValue) : '0');
                                                        }}
                                                        sx={(theme) => ({
                                                            padding: 0,
                                                            color: theme.palette.primary[400],
                                                            '&.Mui-checked': { color: theme.palette.primary.dark },
                                                            '& .MuiSvgIcon-root': { fontSize: 30 }
                                                        })}
                                                        icon={<CheckBoxOutlineBlankOutlined />}
                                                        checkedIcon={<CheckBoxOutlined />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                    {errors[name] && (
                                        <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                            {errors[name]?.message}
                                        </FormHelperText>
                                    )}
                                </>
                            ))}
                        </FormGroup>
                    )}
                />
            </Grid>
        );
    }

    return <></>;
};

export default RenderInputBreakLine;
