import React, { useEffect, useState } from 'react';
import { Edit, Save, Close, Delete } from '@mui/icons-material';
import { GridRowModesModel, GridRowModes, GridActionsCellItem, GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';

export type GridActionsButtonsProps = {
    id: GridRowId;
    row: any;
    rowModesModel: GridRowModesModel;
    handleSaveClick: (id: GridRowId) => () => void;
    handleCancelClick: (id: GridRowId) => () => void;
    handleEditClick: (id: GridRowId) => () => void;
    rows: GridValidRowModel[];
    setRows: (value: React.SetStateAction<GridValidRowModel[]>) => void;
    onClickDelete?: (row: any) => Promise<Record<string, any> | undefined>;
    onClickEdit?: (row: Record<string, any>) => void;
    isPreview?: boolean;
    requiredFields?: string[];
};

export const GridActionsButtons = ({
    id,
    row,
    rowModesModel,
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    rows,
    setRows,
    onClickDelete,
    onClickEdit,
    isPreview,
    requiredFields
}: GridActionsButtonsProps) => {
    const modal = useConfirmationModalContext();
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    const [disabledSaveButton, setDisabledSaveButton] = useState(!!requiredFields?.length);

    useEffect(() => {
        const onEvent = (ev: any) => {
            const { detail } = ev;
            if (detail.id !== id) return;
            const { field, hasError, otherFieldsProps, value, errorMsg } = detail;
            // If field has error disabled button
            if (hasError || errorMsg) {
                setDisabledSaveButton(true);
                return;
            }
            // If all fields are empty disabled button
            const otherFieldsValues = Object.keys(otherFieldsProps).map((oField) => otherFieldsProps[oField]?.value);
            if (otherFieldsValues.length === 0 && (value === null || value === '' || value === undefined)) {
                setDisabledSaveButton(true);
                return;
            }
            // If required fields are empty disabled button
            const requiredFieldsValues = requiredFields?.map((rField) => {
                if (rField === field) return value;
                return otherFieldsProps[rField]?.value;
            }) || [value];
            if (requiredFieldsValues.length === 0) {
                setDisabledSaveButton(true);
                return;
            }
            // If conditions are met enabled button
            setDisabledSaveButton(false);
        };
        window.addEventListener('grid-edit-pre-process', onEvent, false);
        return () => {
            window.addEventListener('grid-edit-pre-process', onEvent, false);
        };
    }, [id, requiredFields]);

    if (isInEditMode) {
        return (
            <>
                <GridActionsCellItem
                    icon={<Save />}
                    label="Save"
                    id="save-button"
                    className="save-button"
                    disabled={disabledSaveButton}
                    data-row-id={id}
                    onClick={handleSaveClick(id)}
                />
                <GridActionsCellItem
                    icon={<Close />}
                    label="Cancel"
                    id="cancel-button"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                />
            </>
        );
    }

    return (
        <>
            <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                className="textPrimary"
                onClick={!isPreview ? () => (onClickEdit ? onClickEdit(row) : handleEditClick(id)()) : undefined}
                color="inherit"
            />
            {onClickDelete && (
                <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    className="textPrimary"
                    onClick={async () => {
                        await modal.showConfirmation({
                            title: 'Delete',
                            icon: <WarningIcon color="warning" sx={{ marginLeft: '1rem' }} fontSize="large" />,
                            content: `Are you sure you want to delete this item?`,
                            loadingText: 'Deleting',
                            forwardButtonText: 'Delete',
                            onForward: async () => {
                                const deletedRow = await onClickDelete(row);
                                if (deletedRow) setRows(rows.filter((x) => x.id !== deletedRow.id));
                            },
                            onAction: null,
                            showCheckbox: false,
                            actionType: 'delete',
                            sectionTitle: 'line_item',
                            recordType: ''
                        });
                    }}
                    color="inherit"
                />
            )}
        </>
    );
};
