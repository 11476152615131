import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isCognitoLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');

    useEffect(() => {
        if (!shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
            navigate(`/setupmfa`, { replace: true });
        }
        if (!isCognitoLoggedIn) {
            navigate(`/login`, { replace: true });
        }
    }, [isCognitoLoggedIn, navigate, shouldSkipSetupMFA, storageshouldSkipSetupMFA]);

    return children;
};

export default AuthGuard;
